import { Screen } from 'public/helpers';
import { Swing, Clock, Spacer, Headquarters } from 'public/components';
import { ExternalLink, MailLink } from 'common/components';

import './grow.less';

import americanDreamLogo from 'public/assets/grow/logos/american-dream.webp';
import appleLogo from 'public/assets/grow/logos/apple.webp';
import gucciLogo from 'public/assets/grow/logos/gucci.webp';
import nikeLogo from 'public/assets/grow/logos/nike.webp';
import nueLifeLogo from 'public/assets/grow/logos/nue-life.webp';
import orangeTheoryLogo from 'public/assets/grow/logos/orange-theory.webp';
import worldQuantLogo from 'public/assets/grow/logos/world-quant.webp';
import sunnovaLogo from 'public/assets/grow/logos/sunnova.webp';

import kirk from 'public/assets/grow/people/kirk.webp';
import florian from 'public/assets/grow/people/florian.webp';
import sebastian from 'public/assets/grow/people/sebastian.webp';
import jan from 'public/assets/grow/people/jan.webp';

import linkedIn from 'public/assets/grow/linked-in.webp';
import ai from 'public/assets/grow/ai.mp4';
import dna from 'public/assets/grow/dna.webp';

export default class Grow extends Screen {
  title = 'What We Do';
  description =
    'From c-suite strategy to on-the-ground implementation, Rekall operates across your organization to define and build data-centric solutions leveraging the latest technology to solve real business problems. ';

  renderBody() {
    return (
      <div {...this.getProps()}>
        <div className={this.getElementClass('container')}>
          <div className="dark">
            <div className={this.getElementClass('page-container')}>
              <div className={this.getElementClass('hero')}>
                <Swing>
                  <h2 className="indent" id="line-1">
                    From strategy to execution, we challenge businesses (large
                    and small) to ignore the impossible and seize upon
                    market-defining business opportunities.
                  </h2>
                </Swing>
                <Spacer size="m" />
                <Swing>
                  <h3 className="indent" id="line-2">
                    REKALL Grow is a global boutique agency for{' '}
                    <em>Strategy</em> and <em>Growth</em>.
                  </h3>
                </Swing>
                <Spacer size="m" />
                <Swing>
                  <h3 className="indent" id="line-3">
                    We focus on brand and products. We are experts in strategy,
                    growth marketing and design. We (net)work in AI, Software
                    and modern brand worlds.
                  </h3>
                </Swing>
                <Spacer size="xl" />
              </div>
              <div id="intro" className={this.getElementClass('container')}>
                <div className={this.getElementClass('logos')}>
                  <img src={appleLogo} />
                  <img src={gucciLogo} />
                  <img src={nikeLogo} />
                  <img src={americanDreamLogo} />
                  <img src={sunnovaLogo} />
                  <img src={worldQuantLogo} />
                  <img src={nueLifeLogo} />
                  <img src={orangeTheoryLogo} />
                </div>
                <Spacer size="l" />
                <div className={this.getElementClass('mail')}>
                  Interested in our growth strategy?{' '}
                  <MailLink
                    mail="info@rekall.ai"
                    className={this.getElementClass('mail-link')}>
                    Email us!
                  </MailLink>
                </div>
                <Spacer size="l" />
                <h3 className={this.getElementClass('heading')}>What we do</h3>
                <Spacer size="l" />
                <div className={this.getElementClass('points')}>
                  <div className={this.getElementClass('points-grid')}>
                    <h3 className={this.getElementClass('points-header')}>
                      Strategy
                    </h3>
                    <div className={this.getElementClass('points-body')}>
                      Do you really know your customer? Do you truly put your
                      customers first in everything you do? How to create
                      products that people not yet know they need, but will buy?
                      We empower businesses through global leading strategy
                      consultation on brand, marketing, repositioning,
                      product/service innovation, and organizational challenges.
                      We offer data-driven insights, sprint experiences, and
                      bespoke consultation to help you succeed and tackle
                      tomorrow's challenges. Fast. Global. Always customer
                      first.
                    </div>
                    <h3 className={this.getElementClass('points-header')}>
                      Product
                    </h3>
                    <div className={this.getElementClass('points-body')}>
                      Rekall is AI. Rekall's elite engineers and data analysts
                      innovate software, offering end-to-end data-centric
                      solutions, horizontally scalable infrastructure, and
                      world-class mobile experiences. Our projects range from
                      App development, to Machine Learning, to decision maker
                      dashboards. Product success requires human-centered,
                      award-winning design. We translate customer insights into
                      design solutions that scale your business success.
                    </div>
                    <h3 className={this.getElementClass('points-header')}>
                      Activation
                    </h3>
                    <div className={this.getElementClass('points-body')}>
                      Our Growth Marketing is building on data driven insights
                      and your needs. We activate your brand. Our AI & Data
                      Driven campaigns are efficient yet effective. Rapid
                      marketing insights put your data to work.
                    </div>
                  </div>
                </div>
                <Spacer size="l" />
                <h3 className={this.getElementClass('heading')}>
                  Superpower Examples
                </h3>
                <Spacer size="l" />
                <div className={this.getElementClass('examples')}>
                  <div className={this.getElementClass('example')}>
                    <div className={this.getElementClass('example-title')}>
                      AI powered Brand Insights
                    </div>
                    <div className={this.getElementClass('example-image')}>
                      <video src={ai} controls />
                    </div>

                    <div className={this.getElementClass('example-body')}>
                      <p>
                        AI driven Brand Intelligence tools help our clients to
                        gain actionable Brand Insights
                      </p>
                      <Spacer size="s" />
                      <ul>
                        <li>
                          AI Models empower strategy insights on brand,
                          customer, business models and markets
                        </li>
                        <li>
                          Intelligence tools help our clients to gain bespoke
                          yet highly actionable Insights
                        </li>
                        <li>
                          Strategy Projects turn into sprints saving clients
                          time and budget while speeding up results
                        </li>
                        <li>
                          Be informed about “why” behind your Business Decisions
                          in fast changing environments
                        </li>
                        <li>
                          Business Insights scale more effective & efficient via
                          our activation team
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Spacer size="m" />
                  <div className={this.getElementClass('example')}>
                    <div className={this.getElementClass('example-title')}>
                      AI powered Creative Intelligence Tool
                    </div>
                    <div className={this.getElementClass('example-image')}>
                      <img src={dna} />
                    </div>
                    <div className={this.getElementClass('example-body')}>
                      <p>
                        Our Ad Creative Intelligence tool helps our clients to
                        save ad budget and time
                      </p>
                      <Spacer size="s" />
                      <ul>
                        <li>Content is tagged with 100+ data points</li>
                        <li>Meta & TikTok performance data enrichment</li>
                        <li>All data is grouped under a single ad ID</li>
                        <li>Ad creative trends visualized on graphs</li>
                        <li>Understand the “why” behind winning ads</li>
                      </ul>
                      <Spacer size="s" />
                    </div>
                  </div>
                  <Spacer size="m" />
                  <div className={this.getElementClass('example-split')}>
                    <div>
                      <p>REDUCE COSTS</p>
                      <p className={this.getElementClass('example-stat')}>
                        25%
                      </p>
                      <p>Reduction in average cost per ad creative</p>
                    </div>
                    <div>
                      <p>LOWER CAC</p>
                      <p className={this.getElementClass('example-stat')}>
                        40%
                      </p>
                      <p>Reduction on average</p>
                    </div>
                    <div>
                      <p>TEST FASTER</p>
                      <p className={this.getElementClass('example-stat')}>
                        10x
                      </p>
                      <p>Faster than hiring creatives in-house</p>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer size="l" />
              <h3 className={this.getElementClass('heading')}>The People</h3>
              <Spacer size="l" />
              <div className={this.getElementClass('people')}>
                <div className={this.getElementClass('person')}>
                  <div className={this.getElementClass('person-image')}>
                    <img src={kirk} />
                  </div>
                  <div className={this.getElementClass('person-title')}>
                    <p>Kirk McMurray</p>
                    <p>CEO</p>
                  </div>
                  <div className={this.getElementClass('person-body')}>
                    Entrepreneur and startup veteran. He has co-founded multiple
                    startups, and has extensive experience in senior product and
                    strategy roles, including serving as CEO at Cognition,
                    CEO/Co-Founder at Totem, CPO/Co-Founder at Voray.
                  </div>
                  <div className={this.getElementClass('person-link')}>
                    <ExternalLink href="https://www.linkedin.com/in/mcmurrak/">
                      <img src={linkedIn} />
                    </ExternalLink>
                  </div>
                </div>
                <div className={this.getElementClass('person')}>
                  <div className={this.getElementClass('person-image')}>
                    <img src={florian} />
                  </div>
                  <div className={this.getElementClass('person-title')}>
                    <p>Florian Ahle</p>
                    <p>Senior Executive Partner </p>
                  </div>
                  <div className={this.getElementClass('person-body')}>
                    Expert for digital transformation, brand strategy and
                    innovation. For +23 yrs he led brand marketing & innovation
                    work at BMW Group & Google, combines his business acumen
                    with academic insights as Strategy course lead at Cambridge
                    University.
                  </div>
                  <div className={this.getElementClass('person-link')}>
                    <ExternalLink href="https://www.linkedin.com/in/florian-ahle-5up3r5t4r/">
                      <img src={linkedIn} />
                    </ExternalLink>
                  </div>
                </div>
                <div className={this.getElementClass('person')}>
                  <div className={this.getElementClass('person-image')}>
                    <img src={sebastian} />
                  </div>
                  <div className={this.getElementClass('person-title')}>
                    <p>Sebastian Koeltzsch</p>
                    <p>Managing Partner</p>
                  </div>
                  <div className={this.getElementClass('person-body')}>
                    Marketing exec with 15+years of experience in cross channel
                    marketing in the US and EMEA, working with global brands
                    such as adidas, Nike, BMW, Mercedes, Samsung, Diageo, P&G,
                    J&J, and many more.
                  </div>
                  <div className={this.getElementClass('person-link')}>
                    <ExternalLink href="https://www.linkedin.com/in/sebastian-koeltzsch-011b9772/">
                      <img src={linkedIn} />
                    </ExternalLink>
                  </div>
                </div>
                <div className={this.getElementClass('person')}>
                  <div className={this.getElementClass('person-image')}>
                    <img src={jan} />
                  </div>
                  <div className={this.getElementClass('person-title')}>
                    <p>Jan Wiechers</p>
                    <p>Partner</p>
                  </div>
                  <div className={this.getElementClass('person-body')}>
                    Has worked in senior roles at Google and YouTube and is an
                    expert in tech monetization & business development, with 15+
                    years consulting and sales experience in the USA, EMEA and
                    Asia.
                  </div>
                  <div className={this.getElementClass('person-link')}>
                    <ExternalLink href="https://www.linkedin.com/in/jan-wiechers-bb93b430/">
                      <img src={linkedIn} />
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </div>
            <Spacer size="l" />
            <Headquarters />
            <Spacer size="l" />
            <div
              ref={this.clocksRef}
              className={this.getElementClass('clocks')}>
              <Clock timezone="America/New_York" />
              <Clock timezone="Europe/Amsterdam" />
              <Clock timezone="Asia/Tokyo" />
            </div>
            <Spacer size="l" />
          </div>
        </div>
      </div>
    );
  }
}
